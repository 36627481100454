import { useEffect } from 'react';
import Link from './link';
import Package from '../../package.json';
import Config from '../config.js';

function Footer() {
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    document.title = Config.SiteName;
  }, []);

  return (
    <div id='footer'>
      <div className='footer-container'>
        <p>Based on the <Link to='https://uptimerobot.com/' text='UptimeRobot' /> API, checking frequency every 5 minutes</p>
        <span className='footer-items'>
          {Config.Foot.map((item, index) => (
            <span key={index}>
              <Link to={item.url} text={item.text} />
              {index !== Config.Foot.length - 1 && <span className='footer-item-spacing'> </span>}
            </span>
          ))}
        </span>
        <p>&copy; {currentYear} <Link to='https://status.noobzhax.com/' text='STATUS.NOOBZHAX.COM' />, Version {Package.version}</p>
      </div>
    </div>
  );
}

export default Footer;
