const SiteName = process.env.REACT_APP_SITE_NAME || 'HaxLab Status';
const ApiKeys = (process.env.REACT_APP_API_KEYS || '').split(',');
const CountDays = parseInt(process.env.REACT_APP_COUNT_DAYS) || 60;
const ShowLink = process.env.REACT_APP_SHOW_LINK === 'true' || true;

const Navi = [
{
  text: 'Homepage',
  url: 'https://status.noobzhax.com/'
},
{
  text: 'Blog',
  url: 'https://noobzhax.com/'
}
];

const Foot = [
{
  text: 'GitHub',
  url: 'https://github.com/noobzhax'
},
{
  text: 'Telegram',
  url: 'https://t.me/noobzhaxme'
}
];

const Config = {
  SiteName,
  ApiKeys,
  CountDays,
  ShowLink,
  Navi,
  Foot
};

export default Config;