import { useMemo } from 'react';
import Link from './link';
import Header from './header';
import Footer from './footer';
import UptimeRobot from './uptimerobot';
import Package from '../../package.json';
import Config from '../config.js';

function App() {
  // console.log(process.env.REACT_APP_SITE_NAME);
  const currentYear = new Date().getFullYear();
  const apikeys = useMemo(() => {
    const ApiKeys = Config.ApiKeys;
    // console.log(ApiKeys)
    if (Array.isArray(ApiKeys)) return ApiKeys;
    if (typeof ApiKeys === 'string') return [ApiKeys];
    return [];
  }, []);

  return (
    <>
      <Header />
      <div className='container'>
        <div id='uptime'>
          {apikeys.map((key) => (
            <UptimeRobot key={key} apikey={key} />
          ))}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
