function Link(props) {
  const { text, to } = props;
  const isExternal = /^https?:\/\//.test(to);
  const url = isExternal ? to : `https://${to}`;

  return (
    <a {...props} href={url} target='_blank' rel='noopener noreferrer'>
      {text}
    </a>
  );
}

export default Link;
